'use client';

import { useSearchParams } from 'next/navigation';

export function PushFlag(props) {
  const searchParams = useSearchParams();
  const push = searchParams.get('push');

  if (!push) {
    return null;
  }

  return props.children;
}
