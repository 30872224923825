export const baseURL = 'https://www.promiflash.de';
export const apiURL = `https://api.promiflash.de/frontendapi/v3`;
export const topListURL = `${apiURL}/top/`;
export const latestListURL = `${apiURL}/articles/`;
export const tvURL = `${apiURL}/tv/current-and-next-up/`;
export const tvBroadcastURL = `${apiURL}/tv/broadcasts/`;
export const generalURL = `${apiURL}/general/`;
const masterGraphql = `https://cms.moving-content.de/frontend-graphql/`; // für staging / testen
const stagingGraphURL = `http://cms-staging-1440232854.eu-west-1.elb.amazonaws.com/frontend-graphql/`; // für staging / testen . Eigenes pf staging mit neuen features. Kann sein, dass er ab und zu weg ist etc. oder kaputt
const prodGraphURL = `https://apiv4.promiflash.de/graphql/`; //  nur die hier für prod benutzen // nur noch persisted queries laufen hier

export const graphURL: string =
  process.env.NODE_ENV !== 'production' ? masterGraphql : prodGraphURL;
